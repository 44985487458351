import { $t } from 'o365-utils';
import {getFullTextSearchString} from './helpers.values.ts';

export default class SearchFunction{
    private _currentOption:string = 'contains_all';
    fnc:string;
    _label:any|null = null;
    operator:string= "search_fnc";
    column:string;
    _value:string|null = null;
    _searchString:string|null = null;
    _currentOptionIndex:number = 1;
  
    options:Array<any> = [
        {name:'contains', title:$t('Contains')},
        {name:'contains_all', title: $t('Contains All')},
        {name:'contains_any', title: $t('Contains Any')},
    ];

    set currentOption(pOption: string){
        this._currentOption = pOption;
        this.setFullTextSearchString(this.value);
        this._currentOptionIndex = this.options.findIndex(x=>x.name == this._currentOption)


    }

    get label(){
        return this._label;
    }
    get searchForLabel(){
        if(this._label)
            return $t('Search for') + ' ' + this._label;

        return $t('Search')+"..."
    }

    set value(pString:string|null){

        this._value = pString;
        this.setFullTextSearchString(pString);
       // if(!pString) this.searchString = null;
        //if(pString) getFullTextSearchString(this._value);
    }

    get currentOptionIndex(){
        return this._currentOptionIndex;
    }

    get value(){
       
        return this._value?this._value:null;
    }

    get sarchStringForSave(){
        return `[_FULL_TEXT_${this.currentOption}] = '${this.value}'`;
    }

    set searchString(pString:string|null){
        this._searchString = pString;
    }

    get searchString(){
       
        return this._searchString?this._searchString:null;
    }

    get isSet(){
        return this.value?true:false;
    }

    get currentOption(){
        return this._currentOption;
    }

    get prettyString(){
        return `${this.currentoptionTitle} (${this.value})`;
    }

    get currentoptionTitle(){
        return this.options.find(x=>x.name == this.currentOption)?.title
    }

    constructor(pOptions:any){
        this.fnc = pOptions.searchFnc;
        this._label = pOptions.label;
        this.column = pOptions.column;
    }

    getFilterString(pValue:string){
        return this.getFullTextSearchString(pValue);
    }

    getPrettyString(pValue:string){
        return `${this.currentoptionTitle} (${pValue})`;

    }

 
    getFullTextSearchString(searchString:string){
       return getFullTextSearchString(searchString);
    }

    setFullTextSearchString(searchString:string|null){
        if(!searchString){
            this._value = null;
            this._searchString = null;
            return;
        }
        this._searchString = this.getFullTextSearchString(searchString);
    }

    setFromUrl(pOptions:Array<any>){
        this.currentOption = this.options[pOptions[1]]?.name;
        this.value = pOptions[2];
    }
    setFromSavedString(pOptions:any){
        this.currentOption = pOptions.column.replace("_FULL_TEXT_","");
        this.value = pOptions.value;
    }

    setFromExternal(pOptions:any){
        if(pOptions.constructor == Array){
            this.setFromUrl(pOptions);
            return
        }

        this.setFromSavedString(pOptions);
    }

    

    
}